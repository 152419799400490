<template>


 <section class="section container columns is-centered  pt-4 ">
    <div class="column  is-half-desktop   ">

            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                     <label class="label pt-2 ">Nombre de anuncio <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                     <label class="label pt-2 ">Nombre de anuncio <span style="color: red">*</span></label>
                </div>

                 <div class="control column    is-flex-grow-0  ">
                    <input class="input" v-model="nombre" type="text" >
                    <p v-show="message_error.nombre_error != ''" class="help is-danger">{{message_error.nombre_error}}</p>
                </div>
            </div>

            <!--
                <div class="control column is-flex-grow-0 ">
                    <div class="select">

                        <select class="options" v-model="AnuncianteSeleccionado">
                            <option v-for="anunciante in anunciantes" :key="anunciante.id" :value="anunciante.id">{{anunciante.nombre}}</option>
                        </select>
                    </div>
                    <p v-show="message_error.anunciante_error != ''" class="help is-danger">{{message_error.anunciante_error}}</p>
                </div>
            </div> -->

            <div  class="field columns has-text-centered">

                <div v-show="!isMobile" class="column  has-text-right">
                    <label class="label pt-2 ">Anunciante </label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2">Anunciante </label>
                </div>


                <div class="control column    is-flex-grow-0  ">
                    <input class="input " type="text" placeholder="Buscar anunciante" v-model="buscar.nombre"   @click="cambiar" >
                    <!-- <div v-show="(buscar.nombre.length != 0) == true && seleccionado==true " >
                        <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                            <li ><a class="button is-white tamaño-boton " style=" text-align: left;" @click="tomarValor(anunciante)" >{{anunciante.nombre}}</a></li>
                        </ul>

                    </div> -->
                     <div v-show=" mostrar && seleccionado==true || (buscar.nombre.length != 0) == true && seleccionado==true ">

                        <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                            <li ><a class="button is-white tamaño-boton is-justify-content-flex-start" style=" text-align: left;" @click="tomarValor(anunciante)"  >{{anunciante.nombre}}</a></li>
                        </ul>
                    </div>
                    <p  class=" help is-danger">Seleccionar lo filtrado</p>
                </div>

<!--
                <div  class="field control columns ">
                    <div v-show="!isMobile" class="control column is-mobile">
                        <input class="input input3" type="text" placeholder="Buscar anunciante" v-model="buscar.nombre">
                        <button class="button is-static mr-3" type="password" >
                            <span class="icon is-right">
                                <i class="fas fa-search" ></i>
                            </span>
                        </button>
                        <div v-show="(buscar.nombre.length != 0) == true && seleccionado==true" >
                            <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                                <li ><a class="button is-white tamaño-boton mr-3" style=" text-align: left;" @click="tomarValor(anunciante)" >{{anunciante.nombre}}</a></li>
                            </ul>
                        </div>
                        <p  class=" help is-danger">Seleccionar lo filtrado</p>
                    </div>
                    <div v-show="isMobile" class="control column is-mobile ml-3">
                        <input class="input input3" type="text" placeholder="Buscar anunciante" v-model="buscar.nombre">
                        <button class="button is-static mr-3" type="password" >
                            <span class="icon is-right">
                                <i class="fas fa-search" ></i>
                            </span>
                        </button>
                        <div v-show="(buscar.nombre.length != 0) == true && seleccionado==true" >
                            <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                                <li ><a class="button is-white tamaño-boton mr-3" style=" text-align: left;" @click="tomarValor(anunciante)" >{{anunciante.nombre}}</a></li>
                            </ul>
                        </div>
                        <p  class=" help is-danger">Seleccionar lo filtrado</p>
                    </div>
                </div> -->
            </div>





           <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                     <label class="label pt-2 ">Estado <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                     <label class="label pt-2 ">Estado <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0 ">
                     <div class="select ">
                        <select class="options" v-model="estado">
                            <option value="HABILITADO">Habilitado</option>
                            <option value="DESHABILITADO">Deshabilitado</option>
                            <option value="TERMINADO">Terminado</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field  columns has-text-centered">

                <div v-show="!isMobile" class="column  has-text-right ">
                        <label class="label pt-5 ">Duracion <span style="color: red">*</span></label>
                </div>

                <div class="column  is-flex-grow-0">
                    <div class="columns  ">
                        <div v-show="!isMobile" class="column  has-text-right">
                            <label class="label pt-2">Inicia</label>
                        </div>
                        <div v-show="isMobile" class="column ">
                            <label class="label pt-2">Inicia</label>
                        </div>
                        <div class="control column ">
                            <input type="date" class="input input2" v-model="inicia"/>
                        </div>
                    </div>
                    <div class="columns ">
                        <div v-show="!isMobile" class="column  has-text-right">
                            <label class="label  pt-2">Finaliza</label>
                        </div>
                        <div v-show="isMobile" class="column ">
                                <label class="label  pt-2">Finaliza</label>
                        </div>
                        <div class="control column">
                                    <input type="date" class="input input2" v-model="finaliza"/>
                        </div>
                    </div>
                    <p v-show="message_error.duracion_error != ''" class="help is-danger">{{message_error.duracion_error}}</p>
                </div>
            </div>
            <div class="columns field  has-text-centered ">
               <div v-show="!isMobile" class="column  has-text-right ">
                     <label class="label pt-2 ">Seleccionar puntos <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column ">
                     <label class="label pt-2 ">Seleccionar puntos <span style="color: red">*</span></label>
                </div>
                <div class="control column  is-flex-grow-0">

                    <div class="select is-multiple">

                        <select multiple class="multiple" v-model="puntoSeleccionado">

                            <option v-for="punto in puntoswifi" :key="punto.id" :value="punto.id">{{punto.nombre}}</option>

                        </select>
                    </div>
                    <p v-show="message_error.punto_error != ''" class="help is-danger">{{message_error.punto_error}}</p>
                    <p class="help is-danger " :class="{'has-text-left':!isMobile}">Mantenga presionado la tecla ctl para seleccionar varios puntos</p>
                    <!-- <p class="pt-3 has-text-weight-bold has-text-black">Costo total: ${{costototal}}</p> -->
                </div>
            </div>
            <div class="field columns has-text-centered ">
                <div v-show="!isMobile" class="column  has-text-right ">
                     <label class="label pt-2 ">Costo total</label>
                </div>
                <div  v-show="isMobile" class="column ">
                     <label class="label pt-2 ">Costo total</label>
                </div>
                <div class="control column    is-flex-grow-0  ">
                    <input class="input has-text-black" v-model="costototal_demo" type="text" disabled readonly>
                </div>
            </div>
            <div class="field  columns has-text-centered ">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Subir archivo <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Subir archivo <span style="color: red">*</span></label>
                </div>
                <div v-show="!isMobile" class="file column is-flex-grow-0 ">
                    <label v-show="!subiendo_imagen" class="file-label " >
                        <input class="file-input " type="file" @change="processFile($event)">
                        <span class="file-cta  has-text-white " style="background-color: #008575; width:17.1rem; ">
                            <span class="file-icon ">
                                <i class="fas fa-upload ml-6" ></i>
                            </span>
                            <span class="file-label ml-6">
                                Subir imagen o video
                            </span>
                        </span>
                    </label>
                    <label v-show="subiendo_imagen" class="file-label " >
                        <button class="button is-loading" style="background-color: #008575; width:17.1rem; "></button>
                    </label>
                </div>
                <div v-show="isMobile" class="file column  is-flex-grow-0">
                    <label v-show="!subiendo_imagen" >
                        <input class="file-input" type="file" @change="processFile($event)">
                        <span class="file-cta  has-text-white " style="background-color: #008575; width:17.1rem; ">
                            <span class="file-icon ">
                                <i class="fas fa-upload ml-6" ></i>
                            </span>
                            <span class="file-label ml-6">
                                Subir imagen o video
                            </span>
                        </span>
                    </label>

                    <label v-show="subiendo_imagen">
                        <button class="button is-loading" style="background-color: #008575; width:17.1rem; "></button>
                    </label>
                </div>
            </div>
            <div v-show="!isMobile" class="columns"  >
                <div class="column is-5"></div>
                <div class="column is-7 has-text-right">
                    <video controls muted :src="url_storage + path" width="270"></video>
                    <p v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
            </div>
            <div v-show="isMobile" class="column has-text-centered">
                <div class="column">
                    <video controls muted :src="url_storage + path" width="270"></video>

                    <p v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
            </div>
            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div class="control column   is-flex-grow-0 ">
                    <textarea class="textarea " placeholder="Escriba un comentario" v-model="comentario"></textarea>
                </div>
            </div>
            <div v-show="!isMobile" class="field columns has-text-centered">
                <div class="column  has-text-right ">
                    <button class="button   " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
                <div v-show="!subiendo" class="control column is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" :disabled="subiendo_imagen" @click="validar">
                        Editar anuncio
                    </button>
                </div>
                <div v-show="subiendo" class="control column is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
            </div>
            <div v-show="isMobile" class="field columns has-text-centered">
                <div v-show="!subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem; " :disabled="subiendo_imagen" @click="validar">
                            Editar anuncio
                    </button>
                </div>
                <div v-show="subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem; ">

                    </button>
                </div>
                <div class="column">
                    <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
            </div>
    </div>

     <div>
        <div class="modal" :class="{'is-active': mostrar_error}">
            <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
            <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                    <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->

                    <p class="has-text-centered has-text-danger">No se completaron los campos requeridos</p>

                    <div class="columns mt-2">
                        <div class="column">
                            <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

import { useRoute, useRouter } from 'vue-router';
import { GraphQLClient } from 'graphql-request'
import { ref } from '@vue/reactivity';
import { computed, inject, watch, watchEffect} from '@vue/runtime-core';
import store from '@/store'
import {queryMe} from '@/composables/queryMe.js'


export default {
    name:'EditarAnuncio',

    created () {
        this.traerAnunciante()
        this.traerPuntoWifi()
        this.traerAnuncio()
    },

    setup(){
        const route = useRoute();
        const router = useRouter()
        const volver = () => {
            router.go(-1)
        }
        const subiendo = ref(false)
        const imagen = ref('');
        const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/duihxozey/image/upload';
        const CLOUDINARY_UPLOAD_PRESET = 'bb7yemvy';
        const path = ref('');
        const id = ref(null);
        const isMobile = inject('isMobile')
        const nombre = ref('');
        const comentario = ref('');
        const costototal = ref(0.0);
        const costototal_demo = ref(0.0);
        const anunciantes = ref([]);
        const AnuncianteSeleccionado = ref(null);
        const puntoswifi = ref([]);
        const puntoSeleccionado = ref([]);
        const puntoOriginal = ref([]);
        const estado = ref('HABILITADO');
        const inicia = ref('');
        const finaliza = ref('');
        const duracion = ref('');
        const error = ref(false)
        const seleccionado = ref(false)
        const buscar = ref({id:'',nombre:''})
        const message_error = ref([
            {nombre_error: '', duracion_error: '', punto_error: '', imagen_error: '', anunciante_error: ''}
        ]);
        const endpoint = store.state.url_backend
        const url_storage = store.state.url_storage

        const subiendo_imagen = ref(false)
        const editando_anuncio = ref(false)
        const mostrar = ref(false)
        const  mostrar_error = ref(false)

         const cambiar= () => {
           mostrar.value = !mostrar.value
        }

        const filtrarAnunciantes = computed(() => {
            return anunciantes.value.filter((anunciante)=>{
                return anunciante.nombre.toLowerCase().match(buscar.value.nombre.toLowerCase())
            })
        })

        const tomarValor = (anunciante) => {

                buscar.value.id = anunciante.id
                buscar.value.nombre = anunciante.nombre

        }

        const processFile = (event) => {
            imagen.value = event.target.files[0]
            message_error.value.imagen_error = ' '
            if (imagen.value.type.includes('mp4') || imagen.value.type.includes('jpg') || imagen.value.type.includes('jpeg') || imagen.value.type.includes('png')) {
                if (imagen.value.size < 16000000) {
                    SubirImage()
                } else {
                    subiendo_imagen.value = false
                    message_error.value.imagen_error = 'El Archivo es muy pesado, asegurese de subir un archivo menor de 16MB'
                }
            } else {
                message_error.value.imagen_error = 'Solo se puede subir video mp4'
            }

        }

        const SubirImage = () => {
            subiendo_imagen.value = true

            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
                mutation($file: Upload!) {
                    uploadFile(file: $file)
                }`,
                {
                    file: imagen.value
                }
            ).then((data) => {
                // console.log(data.data.uploadFile)
                path.value = data.data.uploadFile
                editando_anuncio.value = true
                subiendo_imagen.value = false
            })
            .catch(error => {
                // console.log(error.response)
                editando_anuncio.value = false
                subiendo_imagen.value = false
            })
        }


        watch([puntoSeleccionado, inicia, finaliza, puntoswifi,buscar.value], () => {
            let dur1 = inicia.value.split('-')
            let dur2 = finaliza.value.split('-')

            //   console.log("Campo input: " , buscar.value.nombre,"Id",buscar.value.id)
            if (buscar.value.nombre=='') {
                seleccionado.value = true
                buscar.value.id = null
                //  console.log("Id: " , buscar.value.id)
            }
            if (buscar.value.id!=null && buscar.value.nombre=='') {
                buscar.value.id = null
                // console.log("Id: " , buscar.value.id)
            }
            if (buscar.value.id!=null && buscar.value.nombre!='') {
                seleccionado.value = false
                // console.log("Seleccionado",seleccionado.value)
            }

            if (!puntoswifi.value.length == 0) {
                costototal.value = 0.0

                let d1 = new Date(dur1[0], (dur1[1]), dur1[2]).getTime()
                let d2 = new Date(dur2[0], (dur2[1]), dur2[2]).getTime()
                let dias = (d2 - d1) / 86400000

                puntoSeleccionado.value.forEach(element => {
                    let a = puntoswifi.value.find(item => item.id == element)
                    costototal.value += (a.precioxdia * dias)
                })
            }
            if (costototal.value <= 0) {
                costototal.value = 0.0
            }
            costototal_demo.value = '$' + costototal.value.toString()
        })

        const validar = async () => {
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            // console.log('validando')
            subiendo.value = true
            error.value = false
            message_error.value.nombre_error = ''
            message_error.value.duracion_error = ''
            message_error.value.punto_error = ''
            message_error.value.anunciante_error = ''

            if(message_error.value.imagen_error == '') {
                error.value = true
            }

            if (nombre.value == '') {
                error.value = true

            }
            if (comentario.value == null) {
                comentario.value = ''

            }
            // if (AnuncianteSeleccionado.value == null) {
            //     error.volver = true
            //     message_error.value.anunciante_error = 'Se debe seleccionar un anunciante'
            // }
            if (inicia.value == '' || finaliza.value == '') {
                error.value = true
                message_error.value.duracion_error = 'Los campos fecha no puede estar vacio'

            } else if (inicia.value > finaliza.value) {
                error.value = true
                message_error.value.duracion_error = 'Los campos fecha de la fecha Final no puede ser menor a la fecha Inicial'
            }

            if (puntoSeleccionado.value.length == 0) {
                error.value = true
                message_error.value.punto_error = 'Se debe seleccionar al menos 1 punto de WiFi'
            }

            if (puntoSeleccionado.value.length == null) {
                error.value = true
                message_error.value.punto_error = 'Se debe seleccionar al menos 1 punto de WiFi'
            }

            if (path.value == ''){
                error.value = true
                message_error.value.imagen_error = 'Se debe subir una imagen'
            }

            if (Object.keys(puntoSeleccionado.value).length === 0 || Object.entries(puntoSeleccionado.value).length === 0) {
                error.value = true
                message_error.value.punto_error = 'Se debe seleccionar al menos 1 punto de WiFi'
            }

            if(!error.value) {
                editar()
            } else {
                subiendo.value = false
                mostrar_error.value = true
            }
        }
        const traerPuntoWifi = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {
                client.rawRequest(/* GraphQL */ `
                query{
                    puntowifis(first: 100){
                        data{
                            id,
                            nombre,
                            precioxdia
                        }
                    }
                }`,
                {

                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                }
                ).then((data) => {
                    data.data.puntowifis.data.forEach(element => {
                        puntoswifi.value.push({id: element.id, nombre: element.nombre, precioxdia:element.precioxdia})
                    })
                })
            })

        }

        const traerAnunciante = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query{
                anunciantes(first: 100) {
                    data{
                        id,
                        nombre
                    }
                }
            }`,
            {

            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            }
            ).then((data) => {
                data.data.anunciantes.data.forEach(element => {
                    anunciantes.value.push({id: element.id, nombre: element.nombre})
                });

            })
        }

        const traerAnuncio = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query($id: ID){
                anuncio(id: $id){
                    id,
                    nombre,
                    estado,
                    duracion,
                    comentario,
                    costototal,
                    anunciante{
                        id,
                        nombre
                    },
                    puntowifis{
                        id,
                        nombre
                    },
                    path_img
                }
            }`,
            {
                id : route.params.id
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data.data.anuncio)

                let anuncio = data.data.anuncio
                costototal.value = anuncio.costototal
                id.value = anuncio.id
                // console.log(id.value)
                nombre.value = anuncio.nombre
                comentario.value = anuncio.comentario
                // AnuncianteSeleccionado.value = anuncio.anunciante.id
                if(anuncio.anunciante==null){
                        buscar.value.id = ''
                        buscar.value.nombre = ''

                }else{
                        buscar.value.id = anuncio.anunciante.id
                        buscar.value.nombre = anuncio.anunciante.nombre
                }
                anuncio.puntowifis.forEach(element => {
                    puntoSeleccionado.value.push(element.id)
                })
                puntoOriginal.value = puntoSeleccionado.value
                estado.value = anuncio.estado
                // console.log(JSON.parse(anuncio.duracion).fecha_fin.split('/').reverse().join().replace(/[,]/gi, '-'))
                inicia.value = JSON.parse(anuncio.duracion).fecha_inicio.replace(/[/]/gi, '-')
                finaliza.value = JSON.parse(anuncio.duracion).fecha_fin.replace(/[/]/gi, '-')
                path.value = anuncio.path_img
                // console.log(path.value)

            })
            .catch(error => {
                // console.log(error)
            })
        }

        const editar = () => {

            let dur1 = inicia.value.split('-').reverse()
            let dur2 = finaliza.value.split('-').reverse()

            duracion.value = `{\"fecha_inicio\":\"${dur1[2]}/${dur1[1]}/${dur1[0]}\",\"fecha_fin\":\"${dur2[2]}/${dur2[1]}/${dur2[0]}\"}`

            puntoSeleccionado.value = puntoSeleccionado.value.sort()

            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            // Estructura FetchQL(url, query, variable, opcions)
            client.rawRequest(/* GraphQL */ `
            mutation($id:ID!,$nombre:String!,$comentario:String!,$estado:EstadoAnuncio!,$anunciante_id:ID,$costototal:Float!,$duracion:String!,$puntowifis: [ID!], $path: String!){
                modificaAnuncio(id: $id, input:{

                nombre: $nombre,
                estado: $estado,
                costototal:$costototal,
                duracion: $duracion,
                anunciante_id: $anunciante_id,
                comentario: $comentario,
                puntowifis: $puntowifis,
                path_img: $path
                }){
                    id,
                    nombre,
                    estado,
                    costototal,
                    duracion,
                    comentario,
                    anunciante{
                        id,
                        nombre
                    },
                    puntowifis{
                            id,
                            nombre
                    }

            }}`,
            {
                id: id.value,
                nombre: nombre.value,
                comentario: comentario.value,
                puntowifis: puntoSeleccionado.value,
                // anunciante_id: AnuncianteSeleccionado.value,
                anunciante_id: buscar.value.id,
                estado: estado.value,
                duracion: duracion.value,
                costototal: costototal.value,
                path: path.value,
            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {
                // console.log(data)
                router.push({name: 'TableroAnuncios'})
                let accion = "edicionanuncio"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }


        return{
            volver,
            isMobile,
            nombre,
            puntoSeleccionado,
            inicia,
            finaliza,
            error,
            message_error,
            puntoswifi,
            anunciantes,
            id,
            comentario,
            AnuncianteSeleccionado,
            puntoOriginal,
            estado,
            costototal,
            duracion,
            costototal_demo,
            imagen,
            CLOUDINARY_URL,
            CLOUDINARY_UPLOAD_PRESET,
            subiendo,
            path,
            validar,
            traerPuntoWifi,
            traerAnunciante,
            traerAnuncio,
            editar,
            processFile,
            filtrarAnunciantes,
            tomarValor,
            buscar,
            seleccionado,
            url_storage,
            editando_anuncio,
            subiendo_imagen,
            mostrar,
            cambiar,
            mostrar_error,
        }
    }
}
</script>

<style scoped>


.input{

  width: 17.1rem;

}

.multiple{
      width: 17.1rem;
}

.tamaño-boton{
        width: 17.1rem;
}


.input2{

  width: 12.1rem;

}
.input3{

  width: 14.6rem;

}
.options{
        width: 17.1rem;
}

.select{

    width: 17.1rem;

}

.textarea{

    width: 17.1rem;
}

</style>