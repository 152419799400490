<template>



 <section class="section container columns is-centered  pt-4 ">
    <div class="column  is-half-desktop   ">

            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                     <label class="label pt-2 ">Nombre de anuncio <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                     <label class="label pt-2 ">Nombre de anuncio <span style="color: red">*</span></label>
                </div>
                <div class="control column    is-flex-grow-0  ">
                    <input class="input" v-model="nombre" type="text" >
                    <p v-show="message_error.nombre_error != ''" class="help is-danger">{{message_error.nombre_error}}</p>
                </div>
            </div>


            <div  class="field columns has-text-centered">

                <div v-show="!isMobile" class="column  has-text-right">
                    <label class="label pt-2 ">Anunciante </label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2">Anunciante </label>
                </div>
                <div class="control column    is-flex-grow-0  ">
                    <input class="input " type="text" placeholder="Buscar anunciante" v-model="buscar.nombre"   @click="cambiar" >
                    <!-- <div v-show="(buscar.nombre.length != 0) == true && seleccionado==true " >
                        <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                            <li ><a class="button is-white tamaño-boton " style=" text-align: left;" @click="tomarValor(anunciante)" >{{anunciante.nombre}}</a></li>
                        </ul>

                    </div> -->
                     <div v-show="mostrar && seleccionado==true || (buscar.nombre.length != 0) == true && seleccionado==true ">

                        <ul v-for="anunciante in filtrarAnunciantes"  :key="anunciante.id" >
                            <li ><a class="button is-white tamaño-boton is-justify-content-flex-start"  @click="tomarValor(anunciante)"  >{{anunciante.nombre}}</a></li>
                        </ul>
                    </div>
                    <p  class=" help is-danger">Seleccionar lo filtrado</p>
                </div>

            </div>
           <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">

                    <label class="label pt-2 ">Estado <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Estado <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0 ">
                    <div class="select ">
                        <select class="options" v-model="estado">
                            <option value="HABILITADO">Habilitado</option>
                            <option value="DESHABILITADO">Deshabilitado</option>
                            <option value="TERMINADO">Terminado</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="field  columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-5 ">Duracion <span style="color: red">*</span></label>
                </div>
                <div class="column  is-flex-grow-0">
                    <div class="columns  ">
                        <div v-show="!isMobile" class="column  has-text-right">
                            <label class="label pt-2">Inicia</label>
                        </div>
                        <div v-show="isMobile" class="column ">
                            <label class="label pt-2">Inicia</label>
                        </div>
                        <div class="control column ">
                            <input type="date" class="input input2" v-model="duracion1" />
                        </div>
                    </div>
                    <div class="columns ">
                        <div v-show="!isMobile" class="column  has-text-right">
                            <label class="label  pt-2">Finaliza</label>
                        </div>
                        <div v-show="isMobile" class="column ">
                                <label class="label  pt-2">Finaliza</label>
                        </div>
                        <div class="control column">
                                    <input type="date" class="input input2" v-model="duracion2" />
                        </div>
                    </div>
                    <p v-show="message_error.duracion_error != ''" class="help is-danger">{{message_error.duracion_error}}</p>
                </div>
            </div>

            <div class="columns field  has-text-centered ">
               <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Seleccionar puntos <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column ">
                    <label class="label pt-2 ">Seleccionar puntos <span style="color: red">*</span></label>
                </div>
                <div class="control column  is-flex-grow-0">
                    <div class="  select is-multiple">
                        <select multiple class="multiple" v-model="puntowifis_id">

                            <option v-for="puntowifi in puntoswifi" :key="puntowifi.id" :value="puntowifi.id">{{puntowifi.nombre}}</option>

                        </select>
                    </div>
                    <p v-show="message_error.punto_error != ''" class="help is-danger">{{message_error.punto_error}}</p>
                    <p v-show="!isMobile" class="help is-danger has-text-left">Mantenga presionado la tecla ctl para seleccionar varios puntos</p>
                    <p v-show="isMobile" class="help is-danger has-text-center">Mantenga presionado la tecla ctl para seleccionar varios puntos</p>
                </div>
            </div>

            <!-- Costo total -->
            <div v-show="puntowifis_id.length > 0" class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Costo total</label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Costo total</label>
                </div>
                <div class="control column    is-flex-grow-0  ">
                    <input class="input has-text-black" v-model="costototal_demo" type="text" disabled readonly>
                </div>
            </div>

            <div class="field  columns has-text-centered ">
                <div id="upload_widget" v-show="!isMobile" class="column  has-text-right">
                    <label class="label pt-2 ">Subir archivo <span style="color: red">*</span></label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Subir archivo <span style="color: red">*</span></label>
                </div>
                <div v-show="!isMobile" class="file  column  is-flex-grow-0 ">
                    <label v-show="!subiendo_imagen" class="file-label " >
                        <input class="file-input " type="file" @change="processFile($event)">
                        <span class="file-cta  has-text-white " style="background-color: #008575; width:17.1rem; ">
                            <span class="file-icon ">
                                <i class="fas fa-upload ml-6" ></i>
                            </span>
                            <span class="file-label ml-6">
                                Subir imagen o video
                            </span>
                        </span>
                    </label>
                    <label v-show="subiendo_imagen" class="file-label " >

                        <button class="button is-loading" style="background-color: #008575; width:17.1rem; "></button>
                    </label>
                        <p v-if="!imagen_url" v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
                <div v-show="isMobile" class="file column">
                    <label v-show="!subiendo_imagen">
                        <input class="file-input " type="file" @change="processFile($event)">
                        <span class="file-cta  has-text-white " style="background-color: #008575; width:17.1rem; ">
                            <span class="file-icon ">
                                <i class="fas fa-upload ml-6" ></i>
                            </span>
                            <span class="file-label ml-6">
                                Subir imagen o video
                            </span>
                        </span>
                    </label>

                    <label v-show="subiendo_imagen">
                        <button class="button is-loading" style="background-color: #008575; width:17.1rem; "></button>
                    </label>
                        <p v-if="!imagen_url" v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
            </div>
            <div v-show="!isMobile && imagen_url" class="columns"  >
                <div class="column is-5"></div>
                <div class="column is-7 has-text-right">

                    <video controls muted :src="imagen_url" width="270"></video>

                    <p v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
            </div>
            <div v-show="isMobile && imagen_url" class="column has-text-centered"  >
                <div class="column  ">
                    <video controls muted :src="imagen_url" width="270"></video>

                    <p v-show="message_error.imagen_error != ''" class="help is-danger pr-5">{{message_error.imagen_error}}</p>
                </div>
            </div>



            <div class="field columns has-text-centered">
                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div  v-show="isMobile" class="column   ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div class="control column   is-flex-grow-0 ">
                    <textarea class="textarea " placeholder="Escriba un comentario" v-model="comentario"></textarea>
                </div>
            </div>

            <div v-show="!isMobile" class="field columns has-text-centered">
                <div class="column  has-text-right ">
                    <button class="button" style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
                <div v-show="!subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" :disabled="!nuevo_anuncio" @click="validar">
                        Agregar nuevo anuncio
                    </button>
                </div>
                <div v-show="subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
            </div>
            <div v-show="isMobile" class="field columns has-text-centered">
                <div v-show="!subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" :disabled="!nuevo_anuncio" @click="validar">
                            Agregar nuevo anuncio
                    </button>
                </div>
                <div v-show="subiendo" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
                <div class="column">
                    <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
            </div>
    </div>



    <div>
        <div class="modal" :class="{'is-active': mostrar_error}">
            <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
            <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                    <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->

                    <p v-show="error_campos==false" class="has-text-centered has-text-danger">Su licencia no le permite crear otro anuncio habilitado</p>
                    <p v-show="error_campos==true" class="has-text-centered has-text-danger">No se completaron los campos requeridos</p>

                    <div class="columns mt-2">
                        <div class="column">
                            <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


  </section>





</template>
<script>
import { GraphQLClient } from 'graphql-request';
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
import { computed, inject, watch, watchEffect } from '@vue/runtime-core';
import store from '@/store'
import {queryMe} from '@/composables/queryMe.js'

export default {
    name:'NuevoAnuncio',

    created () {

        this.traerAnunciante()
        this.traerLicencia()
        this.traerPuntoWifi()
        this.traerAnuncio()
    },

    setup(){

        const router = useRouter()
        const volver = () => {
            router.go(-1)
        }
        const imagen = ref('');
        const imagen_url = ref('');
        const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/duihxozey/image/upload';
        const CLOUDINARY_UPLOAD_PRESET = 'bb7yemvy';
        const path = ref('');
        const subiendo = ref(false)
        const isMobile = inject('isMobile')
        const nombre = ref('');
        const estado = ref('HABILITADO');
        const duracion = ref('');
        const duracion1 = ref('');
        const duracion2 = ref('');
        const comentario = ref('');
        const anunciante_id = ref();
        const anunciantes = ref([]);
        const puntoswifi = ref([]);
        const error = ref(false);
        const buscar = ref({id:null,nombre:''})
        const message_error = ref([
            {nombre_error: '', duracion_error: '', punto_error: '', imagen_error: '', anunciante_error: ''}
        ])
        const costototal = ref(0.0)
        const costototal_demo = ref(0.0)
        const puntowifis_id = ref([])
        const endpoint = store.state.url_backend
        const url_storage = store.state.url_storage
        const seleccionado = ref(false)
        const nuevo_anuncio = ref(false)
        const subiendo_imagen = ref(false)
        const anuncios = ref(0)
        const puntos = ref(0)
        const mostrar_error = ref(false)
        const totalAnuncios = ref(0)
        const totalAnunciosHabilitados = ref(0)
        const mostrar = ref(false)
        const error_campos = ref(false)

        const filtrarAnunciantes = computed(() => {
            return anunciantes.value.filter((anunciante)=>{
                return anunciante.nombre.toLowerCase().match(buscar.value.nombre.toLowerCase())
            })
        })

        const tomarValor = (anunciante) => {
            buscar.value.id = anunciante.id
            buscar.value.nombre = anunciante.nombre
        }
        const cambiar= () => {
           mostrar.value = !mostrar.value
        }

        watch([puntowifis_id, duracion1, duracion2,buscar.value], () => {
            let dur1 = duracion1.value.split('-')
            let dur2 = duracion2.value.split('-')
            costototal.value = 0.0
            //   console.log("Campo input: " , buscar.value.nombre,"Id",buscar.value.id)
            if (buscar.value.nombre=='') {
                seleccionado.value = true
                buscar.value.id = null
                // console.log("Id: " , buscar.value.id)
                // console.log("Seleccionado",seleccionado.value)
            }

            if (buscar.value.id!=null && buscar.value.nombre=='') {
                buscar.value.id = null
                // console.log("Id: " , buscar.value.id)
                // console.log("Seleccionado",seleccionado.value)
            }
            if (buscar.value.id!=null && buscar.value.nombre!=''  ) {
                seleccionado.value = false
                // console.log("Seleccionado",seleccionado.value)
                // console.log("Id: " , buscar.value.id)
            }
            let d1 = new Date(dur1[0], (dur1[1]-1), dur1[2]).getTime()
            let d2 = new Date(dur2[0], (dur2[1]-1), dur2[2]).getTime()

            // console.log(d1)
            // console.log(d2)

            let dias = (d2 - d1) / 86400000

            // console.log("dias: ",dias)


            if(dias==0){

                puntowifis_id.value.forEach(element => {
                let a = puntoswifi.value.find(item => item.id == element)
                costototal.value += a.precioxdia

                // console.log(a)
                // console.log("precio de ",a.nombre,"es: ",a.precioxdia, "si dia es igual 0")


                })

            }else{
                puntowifis_id.value.forEach(element => {
                let a = puntoswifi.value.find(item => item.id == element)
                costototal.value += (a.precioxdia * (dias + 1))

                // console.log(a)
                // console.log("precio de ",a.nombre,"es: ",a.precioxdia, "si dia no es igual 0")

                })
            }


            if (costototal.value < 0) {
                costototal.value = 0.0
            }
            // console.log(costototal.value)
            costototal_demo.value = '$' + costototal.value.toString()


        })

        const traerPuntoWifi = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query{
                puntowifis(first: 100){
                    data{
                        id,
                        nombre,
                        precioxdia
                    }
                }
            }`,
            {

            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            }
            ).then((data) => {
                // console.log(data)
                data.data.puntowifis.data.forEach(element => {
                    puntoswifi.value.push({id: element.id, nombre: element.nombre, precioxdia: element.precioxdia})
                })
            })
        }



        const traerLicencia = () =>{
            watchEffect(() => {
                const client = new GraphQLClient(endpoint)

                client.rawRequest(/* GraphQL */ `
                  query {
                        me {
                            empresa {
                            id
                            habilitada
                                licencia {
                                    id
                                    puntos
                                    anuncios
                                }
                            }
                        }
                        }`,
                    {

                    },
                     {
                        authorization: `Bearer ${ localStorage.getItem('user_token') }`
                    })
                    .then((data) => {

                        let me = data.data.me
                        // console.log(me)
                        anuncios.value = me.empresa.licencia.anuncios
                        puntos.value = me.empresa.licencia.puntos

                    //  if(licencia.value==null){
                    //      licencia.value =''
                    //  }
                    //    console.log(licencia.value)
                        }).catch(error => {
                        // console.log(error.response);
                     })
            })
        }


        const traerAnunciante = () => {
            // const endpoint = "http://45.70.8.41:88/graphql" // guardamos la url del playground
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query{
                anunciantes(first: 100) {
                    data{
                        id,
                        nombre
                    }
                }
            }`,
            {
                //variables
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            }
            ).then((data) => {
                data.data.anunciantes.data.forEach(element => {
                    anunciantes.value.push({id: element.id, nombre: element.nombre})
                });

                // Trae la fecha actual
                let f = new Date()
                // console.log(f.getMonth()+1)
                if (f.getDate() < 10) {
                    if (f.getMonth() < 10) {
                        duracion1.value = f.getFullYear() + '-0' + (f.getMonth()+1) + '-0' + f.getDate()
                    } else {
                        duracion1.value = f.getFullYear() + '-' + (f.getMonth()+1) + '-0' + f.getDate()
                    }
                } else {
                    if (f.getMonth() < 10) {
                        duracion1.value = f.getFullYear() + '-0' + (f.getMonth()+1) + '-' + f.getDate()
                    } else {
                        duracion1.value = f.getFullYear() + '-' + (f.getMonth()+1) + '-' + f.getDate()
                    }
                }
                duracion2.value = duracion1.value
            })
        }

        const validar = async () => {
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            subiendo.value = true
            error.value = false
            message_error.value.nombre_error = ''
            message_error.value.duracion_error = ''
            message_error.value.punto_error = ''
            message_error.value.anunciante_error = ''

            if(message_error.value.imagen_error != '') {
                error.value = true
            }

            if (nombre.value == '') {
                error.value = true
                message_error.value.nombre_error = 'El campo no puede estar vacio'
            }
            if(totalAnunciosHabilitados.value == anuncios.value){
                    error.value = true
                    mostrar_error.value = !mostrar_error.value
             }
                    // console.log(mostrar_error)

            if (duracion1.value == '' || duracion2.value == '') {
                error.value = true
                message_error.value.duracion_error = 'Los campos fecha no puede estar vacio'
            } else if (duracion1.value > duracion2.value) {
                error.value = true
                message_error.value.duracion_error = 'Los campos fecha de la fecha Final no puede ser menor a la fecha Inicial'
            }
            // console.log(this.puntowifis_id.length)
            if (puntowifis_id.value.length == 0) {
                error.value = true
                message_error.value.punto_error = 'Se debe seleccionar al menos 1 punto de WiFi'
            }
            if (comentario.value == null) {
                    comentario.value = ''
            }

            if (imagen_url.value == ''){
                error.value = true
                message_error.value.imagen_error = 'Se debe subir una imagen'
            }

            if(!error.value && nuevo_anuncio.value) {
                registrar()

            } else {
                // console.log('error')
                mostrar_error.value = !mostrar_error.value
                error_campos.value = true
                subiendo.value = false
            }
        }


        const traerAnuncio = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {

                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first:Int!){
                    anuncios(first: $first, page: $page){
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data{
                            id,
                            nombre,
                            estado,
                            duracion,
                            costototal,
                            comentario,
                            anunciante{
                                id,
                                nombre
                            }
                            puntowifis{
                                id
                                nombre
                            },
                            path_img
                        }
                    }
                }`,
                {
                    first: 100
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then(data => {
                    // console.log(data)
                    let anunHabilitados = data.data.anuncios.data
                    // let contar = 0

                    totalAnuncios.value = data.data.anuncios.paginatorInfo.total

                    anunHabilitados.forEach(element => {
                            // console.log(element.estado)
                            if(element.estado == 'HABILITADO'){
                                    totalAnunciosHabilitados.value +=1
                            }
                    });
                    // console.log(totalAnunciosHabilitados.value)


                })
                .catch(error => {
                    // console.log(error.response)
                })
            })
        }

        const registrar = () => {

            if (buscar.value.nombre.length==0) {
                // error.value = true
               buscar.value.id == null
                // message_error.value.anunciante_error= 'Debe seleccionar un anunciante
            }

            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego
            let dur1 = duracion1.value.split('-')
            let dur2 = duracion2.value.split('-')
            duracion.value = `{\"fecha_inicio\":\"${dur1[0]}/${dur1[1]}/${dur1[2]}\",\"fecha_fin\":\"${dur2[0]}/${dur2[1]}/${dur2[2]}\"}`
            // console.log(estado.value)

            // Estructura FetchQL(url, query, variable, opcions)
            client.rawRequest(/* GraphQL */ `
            mutation($nombre:String!, $estado: EstadoAnuncio!, $duracion: String!, $comentario: String!, $costototal: Float!, $anunciante_id: ID, $puntowifis: [ID!]!, $path: String!){
                creaAnuncio(input:{
                    nombre: $nombre,
                    estado: $estado,
                    duracion: $duracion,
                    comentario: $comentario,
                    costototal: $costototal,
                    anunciante_id: $anunciante_id,
                    puntowifis: $puntowifis
                    path_img: $path
                }){
                    id,
                    nombre,
                    estado,
                    duracion
                    costototal,
                    anunciante{
                        id,
                        nombre
                    },
                    puntowifis{
                        id,
                        nombre
                    },
                    path_img
                }
                }`,
                {
                    nombre: nombre.value,
                    estado: estado.value,
                    duracion: duracion.value,
                    comentario: comentario.value,
                    costototal: costototal.value,
                    // anunciante_id: anunciante_id.value,
                    anunciante_id: buscar.value.id,
                    puntowifis: puntowifis_id.value,
                    path: path.value,
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    // console.log(data)
                    router.push({name: 'TableroAnuncios'})
                    let accion = "nuevoanuncio"
                    store.commit('verificar_carga',accion)

                }).catch(error => {
                    // if(totalAnuncios.value >= anuncios.value){
                    //     mostrar_error.value = !mostrar_error.value
                    // }
                    // console.log(mostrar_error)
                    // console.log(error.response);
                })
        }

        const processFile = (event) => {
            imagen.value = event.target.files[0]

            // console.log(imagen.value)
            message_error.value.imagen_error = ''
            //if (imagen.value.type.includes('mp4') || imagen.value.type.includes('jpg') || imagen.value.type.includes('jpeg') || imagen.value.type.includes('png')) {
                if (imagen.value.size < 16000000) {
                    SubirImage()
                } else {
                    subiendo_imagen.value = false
                    message_error.value.imagen_error = 'El Archivo es muy pesado, asegurese de subir un archivo menor de 16MB'
            }
        }

        const SubirImage = () => {
            // console.log(imagen.value)
            subiendo_imagen.value = true

            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
                mutation($file: Upload!) {
                    uploadFile(file: $file)
                }`,
                {
                    file: imagen.value
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                }
            ).then((data) => {
                // console.log(data.data.uploadFile)
                path.value = data.data.uploadFile
                nuevo_anuncio.value = true
                subiendo_imagen.value = false
                imagen_url.value = url_storage + path.value
                // registrar()
            })
            .catch(error => {
                // console.log(error)
                nuevo_anuncio.value = false
                subiendo_imagen.value = false
            })
        }

        return{
            volver,
            isMobile,
            puntoswifi,
            puntowifis_id,
            duracion1,
            duracion2,
            anunciantes,
            message_error,
            error,
            nombre,
            estado,
            buscar,
            comentario,
            anunciante_id,
            costototal,
            costototal_demo,
            imagen,
            imagen_url,
            CLOUDINARY_URL,
            CLOUDINARY_UPLOAD_PRESET,
            path,
            subiendo,
            filtrarAnunciantes,
            tomarValor ,
            traerPuntoWifi,
            traerAnunciante,
            validar,
            registrar,
            processFile,
            url_storage,
            seleccionado,
            nuevo_anuncio,
            subiendo_imagen,
            anuncios ,
            puntos ,
            mostrar_error ,
            totalAnuncios,
            traerLicencia ,
            traerAnuncio,
            mostrar ,
            cambiar,
            totalAnunciosHabilitados,
            error_campos,
        }
    }
}
</script>

<style scoped>


.input{

  width: 17.1rem;

}

.multiple{
      width: 17.1rem;
}

.tamaño-boton{
        width: 17.1rem;
}

.input2{

  width: 12.1rem;

}

.input3{

  width: 14.6rem;

}

.options{
        width: 17.1rem;
}

.select{

    width: 17.1rem;

}

.textarea{

    width: 17.1rem;
}



</style>