<template>


  <section class="section container columns is-centered pt-4 ">
    <div class="column is-half-desktop">

            <div class="field columns has-text-centered">
                 <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Nombre del anunciante <span style="color: red">*</span></label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Nombre del anunciante <span style="color: red">*</span></label>
                </div>
                <div class="control column  is-align-self-center is-flex-grow-0  ">
                    <input  v-model="nombre" name="nombre" class="input" type="text">
                    <p v-show="message_error.nombre_error != ''" class="help is-danger">{{message_error.nombre_error}}</p>
                </div>
            </div>

            <div class="field columns has-text-centered">
                 <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Telefono</label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Telefono</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input class="input " type="text" v-model="telefono" >
                </div>
            </div>

            <div class="field  columns has-text-centered">
                 <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Email <span style="color: red">*</span></label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Email <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input v-model="email" name="email" class="input" type="text">
                    <p v-show="message_error.email_error != ''" class="help is-danger">{{message_error.email_error}}</p>
                </div>
            </div>

            <!-- <div class="field  columns has-text-centered">
                <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Correo alternativo</label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Correo alternativo</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input class="input" type="text" v-model="email_alt" >
                </div>
            </div> -->

            <div class="field  columns has-text-centered">
                 <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Domicilio</label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Domicilio</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input class="input" type="text" v-model="domicilio">
                </div>
            </div>


            <div class="field columns has-text-centered">
                 <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Ciudad <span style="color: red">*</span></label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Ciudad <span style="color: red">*</span></label>
                </div>
                <div class="control column is-flex-grow-0 ">
                    <div class="select" >

                        <select class="options" v-model="CiudadSeleccionada">
                            <option v-for="ciudad in ciudades" :key="ciudad.id" :value="ciudad.id">{{ciudad.nombre}}</option>
                        </select>

                    </div>
                    <p v-show="message_error.ciudad_error != ''" class="help is-danger">{{message_error.ciudad_error}}</p>
                </div>
            </div>


            <div class="field columns has-text-centered">

                <div v-show="isMobile" class="column  ">
                    <label class="label pt-2 ">Comentario</label>
                </div>

                <div v-show="!isMobile" class="column  has-text-right ">
                    <label class="label pt-2 ">Comentario</label>
                </div>
                <div class="control column   is-flex-grow-0 ">
                    <textarea class="textarea" placeholder="Escriba un comentario" v-model="comentario"></textarea>
                </div>
            </div>
            <div v-show="!isMobile" class="field columns ">
                <div class="column   has-text-right">
                    <button class="button" style=" background-color: #DD2923 ; color:white;" @click="volver">
                            Cancelar
                    </button>
                </div>
                <div class="control column   is-flex-grow-0 ">
                    <button v-show="!cargando" class="button  is-success " style="width: 17.1rem;" @click="validar">
                            Agregar nuevo anunciante
                    </button>
                    <button v-show="cargando" class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
            </div>
                <div v-show="isMobile" class="field columns has-text-centered">
                <div class="control column   is-flex-grow-0 ">
                    <button v-show="!cargando" class="button  is-success " style="width: 17.1rem;"  @click="validar">
                            Agregar nuevo anunciante
                    </button>
                    <button v-show="cargando" class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
                <div class="column   ">
                    <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
            </div>

    </div>

     <div>
        <div class="modal" :class="{'is-active': mostrar_error}">
            <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
            <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                    <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->

                    <p class="has-text-centered has-text-danger">No se completaron los campos requeridos</p>

                    <div class="columns mt-2">
                        <div class="column">
                            <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


  </section>


</template>

<script>

import { GraphQLClient } from 'graphql-request'
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
import store from '@/store';
import { inject } from '@vue/runtime-core';
import {queryMe} from '@/composables/queryMe.js'

export default {
    name: 'AgregarAnunciante',

    created () {
        this.traerCiudades()
    },
    setup(){
        const router = useRouter()
        const isMobile = inject('isMobile')
        const error = ref(false)
        const message_error = ref([
            { nombre_error: '', email_error: '' ,ciudad_error:''}
        ])
        const nombre = ref('')
        const email = ref('')
        const telefono = ref('')
        const email_alt = ref('')
        const domicilio = ref('')
        const comentario = ref('')
        const ciudades = ref([])
        const endpoint = store.state.url_backend
        const CiudadSeleccionada = ref(null)
        const cargando = ref(false)
        const mostrar_error = ref(false)

        const volver = () => {
            router.go(-1)
        }
        const traerCiudades = () => {

            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query{
                municipios(first: 100){
                    data{
                        id
                        nombre,
                    }
                }
            }`,
            {
                //variables
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)

                data.data.municipios.data.forEach(element => {
                    ciudades.value.push({id: element.id ,nombre: element.nombre})
                    // CiudadSeleccionada.value = element.id
                });

            }).catch(error => {
            //   console.log(error.response);

            })
        }

        const validar = async () => {
            cargando.value = true
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            error.value = false
            message_error.value.nombre_error = ''
            message_error.value.email_error = ''
            message_error.value.ciudad_error = ''

            if(nombre.value == '') {
                error.value = true
                message_error.value.nombre_error =  'El campo no puede estar vacio'
            }
            if (CiudadSeleccionada.value == null) {
                error.value = true
                message_error.value.ciudad_error = 'Se debe seleccionar una ciudad'
            }
            validar_email()

            if(error.value) {
                cargando.value = false
                mostrar_error.value = true

                // console.log('error')
            } else {
                // console.log('paso')
                registrar()
            }

        }

        const validar_email = () => {
            if(email.value == '') {
                error.value = true
                message_error.value.email_error = 'El campo email no puede estar vacio'
            } else if (!regex_email_validador(email.value)) {
                error.value = true
                message_error.value.email_error = 'El email debe tener un formato correcto'
            }
        }

        const regex_email_validador = () => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email.value)
        }

        const registrar = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            // Estructura FetchQL(url, query, variable, opcions)
            client.rawRequest(/* GraphQL */ `
            mutation($nombre:String!, $domicilio:String!,$comentario:String!,$email:String!,$municipio_id:ID!,$telefono:String!){
              		creaAnunciante(input:{

                    nombre: $nombre,
                    telefono: $telefono,
                    email: $email,
                    # email_alternativo:$email_alternativo ,
                    domicilio: $domicilio,
                    municipio_id: $municipio_id,
                    comentario: $comentario,

                    }){
                        id,
                        nombre,
                        telefono,
                        email,
                        # email_alternativo,
                        domicilio,
                        comentario,
            }
            }`,
            {
                nombre: nombre.value,
                domicilio: domicilio.value,
                email: email.value,
                comentario: comentario.value,
                municipio_id: CiudadSeleccionada.value,
                // email_alternativo: email_alt.value,
                telefono: telefono.value
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                router.push({name: 'TableroAnunciante'})
                let accion = "nuevoanunciante"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }
        return{
            isMobile,
            error,
            message_error,
            nombre,
            email,
            telefono,
            email_alt,
            domicilio,
            comentario,
            ciudades,
            CiudadSeleccionada,
            volver,
            traerCiudades,
            validar,
            registrar,
            cargando,
            mostrar_error
        }
    }
}
</script>

<style scoped>


.input{

  width: 17.1rem;

}

.options{
        width: 17.1rem;
}

.select{

    width: 17.1rem;

}

.textarea{

    width: 17.1rem;
}

</style>